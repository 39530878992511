<template>
  <div class="page container">
    <div class="columns">
      <div class="column">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            src="@/assets/img/anon-avatar.png"
            alt="Establecer nueva contraseña"
          >
        </div>
        <b-field>
          <b-input
            ref="password"
            v-model="form.password"
            type="password"
            placeholder="Contraseña"
            required
            maxlength="20"
            password-reveal
          />
        </b-field>
        <b-field>
          <b-input
            ref="repeatPassword"
            v-model="form.repeat_password"
            type="password"
            placeholder="Repita contraseña"
            required
            maxlength="20"
          />
        </b-field>
        <div class="mt-3">
          <b-button
            type="is-primary is-uppercase"
            expanded
            @click="changePassword"
          >
            Cambiar contraseña
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  title: 'Modificar contraseña',
  name: 'ChangePassword',
  components: {},
  data: function () {
    return {
      isValid: false,
      form: {
        token: null,
        password: '',
        repeat_password: ''
      }
    }
  },
  computed: {},
  beforeMount() {
    this.form.token = this.$router.currentRoute.params.token
  },
  methods: {
    async changePassword() {
      this.validateForm()

      if (this.isValid) {

        try {
          await this.$store.dispatch('initLoading')

          const response = await this.$api.contact.send(this.form)

          await this.$store.dispatch('finishLoading')

          this.$msg.success(response.message)

          this.resetForm()
        } catch (error) {
          await this.$store.dispatch('finishLoading')
          const data = error.response.data
          this.$msg.error(data.message)
        }
      }
    },
    validateForm() {
      const validateFields = ["token", "password", "repeatPassword"];
      let isValidFormData = true

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          const itemIsValid = this.$refs[item].checkHtml5Validity()
          isValidFormData = isValidFormData && itemIsValid
        }
      })

      this.isValid = isValidFormData
    },
    resetForm() {
      this.form.password = ''
      this.form.repeat_password = ''
      this.form.token = null
    },
  }
}
</script>
